<template>
  <div class="mx-a mb-16">
    <div class="current-bar pyx mb-16">
      <router-link
        v-for="nav in routerList"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${
          nav.title === '지난 등록 내역' ? 'selected' : ''
        }`"
      >
        {{ nav.title }}
      </router-link>
    </div>
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">등록 정보</span>
        </div>
      </div>
      <div class="content-section-content" style="padding: 24px">
        <div class="flex-align-center">
          <span class="content-approve-title">등록일시</span>
          <span class="content-approve-content"
            >{{ acceptDate }} - {{ endDate }}</span
          >
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">결제항목 코드</span>
          <span class="content-approve-content">{{ esntlId }}</span>
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">카테고리</span>
          <span class="content-approve-content"
            >{{ highCateName }} > {{ lowCateName }}</span
          >
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">파트너 코드</span>
          <span class="content-approve-content">{{ partnerEsntlId }}</span>
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">계약 유형</span>
          <span class="content-approve-content">{{
            getCompStatus(compStatus)
          }}</span>
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">파트너명</span>
          <span class="content-approve-content">{{ compName }}</span>
        </div>
        <div class="flex-align-center" style="margin-top: 38px">
          <span class="content-approve-title">아이디</span>
          <span class="content-approve-content">{{ email }}</span>
        </div>
      </div>
    </div>
    <!-- 결제 항목 -->
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">결제 항목</span>
        </div>
      </div>
      <div class="content-section-content">
        <div class="course-wrap">
          <draggable v-model="grpArr" handle=".grp-bars">
            <transition-group>
              <payment-grp
                v-for="(v, i) in grpArr"
                :key="v.id"
                :item="v.payment"
                :name="`${v.name} ${i + 1}`"
                :allDisabled="true"
                @remove="onRemoveGrp(v.id)"
                @input="onInputPayment($event, i)"
                group="group"
              />
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common :size="SIZE_T" :clr="CLR_W" @click="goBack"
          >돌아가기</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import PaymentGrp from "@/components/ui/payment/PaymentGrp";

export default {
  name: "CompanyPrevDetail",
  mixins: [],
  components: {
    draggable,
    PaymentGrp,
  },

  /*** data ***/
  data() {
    return {
      titleType: "NEW",
      highCateList: [],
      cateList: [],
      grpArr: [],
      sendParams: {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      },
      isReset: false,
      acceptDate: "",
      endDate: "",
      esntlId: "",
      highCateName: "",
      lowCateName: "",
      partnerEsntlId: "",
      compStatus: "",
      compName: "",
      email: "",
      toRoute: {
        path: "",
        meta: "",
      },
      routerList: [],
    };
  },

  /*** created ***/
  async created() {
    await this.$store.dispatch("A_NAV_SHOW", false);
  },

  /*** mounted ***/
  async mounted() {
    this.routerList.push({
      id: "11-2-1-3",
      path: `/company/payment/approve/${this.$route.params.id}`,
      title: "신청 항목",
    });
    this.routerList.push({
      id: "11-2-1-4",
      path: `/company/payment/status/${this.$route.params.id}`,
      title: "등록 현황",
    });
    this.routerList.push({
      id: "11-2-1-5",
      path: `/company/payment/prev/${this.$route.params.id}`,
      title: "지난 등록 내역",
    });
    await this.getPrevDetail();
    await this.getHighCategoryList();
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_NAV_ID"]),
    getHighCateName() {
      let highCateList = this.highCateList.filter(
        (item) => this.sendParams.highCategoryEsntlId === item.esntlId
      );
      return highCateList.length > 0 ? highCateList[0].title : "";
    },
    getCateName() {
      let cateList = this.cateList.filter(
        (item) => this.sendParams.lowCategoryEsntlId === item.esntlId
      );
      return cateList.length > 0 ? cateList[0].title : "";
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 지난 등록 내역 상세 ***/
    async getPrevDetail() {
      const rs = await this.$axios({
        url: "/admin/service/prev/approval/detail",
        params: {
          esntlId: this.$route.params.id,
          partnerEsntlId: this.$route.params.partId,
        },
      });
      if (rs.result === "SUC") {
        this.setData(rs.resultData);
      } else {
        console.log("지난 등록 상세 조회 실패");
      }
    },

    /** @ 상세조회 데이터 세팅 ***/
    setData(data) {
      if (data) {
        this.acceptDate = data.acceptDate;
        this.endDate = data.endDate;
        this.esntlId = data.esntlId;
        this.highCateName = data.highCategoty;
        this.lowCateName = data.lowCategoty;
        this.partnerEsntlId = data.partnerEsntlId;
        this.compStatus = data.compStatus;
        this.compName = data.compName;
        this.email = data.email;
        this.sendParams.highCategoryEsntlId = data.highCategoryEsntlId;
        this.sendParams.lowCategoryEsntlId = data.lowCategoryEsntlId;
        // 서비스 리스트 순서 정렬
        this.grpArr = [];
        data.serviceList.forEach(item => {
          const content = JSON.parse(JSON.stringify(item));
          content.itemList.sort((a,b) => {
            return a.numberOrder - b.numberOrder
          })
          this.grpArr.push({ id: this.$nnid(), name: `항목`, payment: content })
        })
        this.grpArr.sort((a,b) => {
          return a.payment.numberOrder - b.payment.numberOrder
        });
      }
    },

    /** @ 카테고리 상위키 조회 ***/
    async getHighCategoryList() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCateList = rs.resultList;
        if (this.sendParams.highCategoryEsntlId) {
          this.selectHighCate(this.sendParams.highCategoryEsntlId);
        }
      } else {
        console.log("상위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 하위키 조회 ***/
    async selectHighCate(id) {
      this.sendParams.highCategoryEsntlId = id;
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: id },
      });
      if (rs.result === "SUC") {
        this.cateList = rs.resultList;
      } else {
        console.log("하위 카테고리 조회 실패");
      }
    },

    selectCate(cate) {
      this.sendParams.lowCategoryEsntlId = cate.esntlId;
    },

    resetParams() {
      this.titleType = "NEW";
      this.grpArr = [];
      this.sendParams = {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      };
      this.acceptStatus = null;
      this.acceptDate = "";
      this.rejectReason = "";
    },

    // 결제 그룹 추가
    addPaymentGroup() {
      const content = {
        name: "", // 이름
        type: "", // 유형
        price: "", // 할인전 금액
        minCnt: "", // 월 최소 횟수
        maxCnt: "", // 월 최대 횟수
        itemList: [], // 할인율 배열
        penalty: "", // 해지위약금
      };
      this.grpArr.push({ id: this.$nnid(), name: `항목`, payment: content });
    },

    /** @ 취소 ***/
    onPageReset() {
      this.$openConfirm(
        {
          cbPrmy: this.goPage,
          bodyTx: "작성 중인 내용을 취소하시겠습니까?",
        },
        this
      );
    },

    /**@ 모달 CB ***/
    // 모달콜백: 취소
    goPage() {
      this.isReset = true;
      this.$router.push({
        path: this.toRoute.path,
        meta: { id: this.toRoute.meta },
      });
    },

    goBack() {
      this.$router.back();
    },

    // 그룹 삭제
    async onRemoveGrp(id) {
      await this.$openConfirm(
        {
          bodyTx: "해당 그룹을 삭제하시겠습니까?",
          cbPrmy: this.cbDeleteGrp,
          data: { id },
        },
        this
      );
    },

    // 모달콜백: 그룹 삭제
    cbDeleteGrp(val) {
      this.grpArr = this.grpArr.filter((item) => item.id !== val.id);
    },

    // 코스 데이터 입력시
    onInputPayment(payment, idx) {
      this.grpArr[idx].payment = payment;
    },

    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },

    getAcceptStatus() {
      switch (this.acceptStatus) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
        default:
          return "처리중";
      }
    },

    getAcceptStatusColor() {
      switch (this.acceptStatus) {
        case "APP":
          return "complete";
        case "REJ":
          return "reject";
        default:
          return "process";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-circle {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #515466;
  margin-right: 5px;
}
.content-info {
  font-size: 12px;
  font-weight: 400;
  color: #515466;
}
.content-section-header {
  height: 53px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8dae5;
}
.content-section-content {
  padding: 8px 32px;
}
.content-category-select {
  padding: 16px 16px 0 16px;
  width: 510px;
  height: 160px;
  border: 1px solid #d8dae5;
  overflow-y: scroll;
}
.content-category-select-item {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #828699;
  font-size: 14px;
}
.content-category-select-item-active {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #2CCDC3;
  font-size: 14px;
  background: #f1f2f8;
}
.content-category-arrow {
  margin-left: 40px;
  margin-right: 40px;
}
.course-wrap {
  padding: 16px 0;
}
.coach-table-null {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  border: 1px solid #d8dae5;
}
.search-table-wraaper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 320px;
  overflow-y: scroll;
}
.search-table-wraaper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.search-table-wraaper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.content-approve-title {
  color: #828699;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
}
.content-approve-content {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
}
</style>
